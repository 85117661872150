import {Col, Row, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import styles from "./Footer.module.scss";
import {dataSourceNav} from "./dataSource"
import {NavLink} from "react-router-dom";
import {scrollTo, toRemAuto} from "../../utils/utils";
import EventBus from "../../envent_bus/event_bus";
import {CaseListener} from "../../envent_bus/events";
import {assetsUrl} from "../../constants/constants";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (document.documentElement.clientWidth <= 900) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
    return () => {
      window.removeEventListener("resize", () => {
      })
    }
  }, [])
  const btnProductScroll = (path: string, state: any) => {
    if (path === "/case") {
      EventBus.fire(CaseListener.eventName, new CaseListener(state));
      document.body.scrollIntoView();
      return;
    }
    if (path === window.location.pathname && state !== undefined) {
      scrollTo(state)
    } else {
      document.body.scrollIntoView();
    }
  };
  return (
    <div className={styles.foot_bck}>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={6}>
          <div className={styles.foot_left}>
            <span className={styles.foot_left_title}>咨询热线</span>
            <span className={styles.foot_left_big_text}>4008-922-177</span>
          </div>
          <div style={{marginTop: "20px"}} className={styles.foot_left}>
            <span className={styles.foot_left_title}>电子邮箱</span>
            <span className={styles.foot_left_text}>xyzayao@yiwoaikeji.com</span>
          </div>
          <div style={{marginTop: "20px"}} className={styles.foot_left}>
            <span className={styles.foot_left_title}>地址</span>
            <span className={styles.foot_left_text}>广州市越秀区泰兴商业大厦606</span>
          </div>
          <div style={{marginTop: "20px", display: "flex", marginLeft: toRemAuto(-40)}}>
            <Tooltip color={"#ffffff"}
                     title={<img style={{width: "80px", height: "80px"}}
                                 src={assetsUrl + "public/picture_QR_code.png"} />}
                     placement="top">
              <img style={{width: "30px", height: "30px", marginLeft: "38px"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/footer/icon_WeChat.png"} />
            </Tooltip>
            <Tooltip
              color={"#ffffff"}
              placement="top"
              title={<img style={{width: "80px", height: "80px"}}
                          src={assetsUrl + "public/icon_video_code.png"} />}>
              <img style={{width: "30px", height: "30px", marginLeft: "16px"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/footer/icon_app.png"} />
            </Tooltip>
          </div>
        </Col>
        <Col xs={0} md={1}>
          <div className={styles.foot_dived} />
        </Col>
        <Col xs={24} md={17}>
          <Row gutter={[16, 16]}>
            {dataSourceNav.map((items, index) => {
              return (
                <Col key={index.toString()} xs={24} md={12} lg={4}>
                  <div>
                    <NavLink to={items.path}>
                      <span className={styles.foot_right_title}>{items.title}</span>
                    </NavLink>
                    <div style={{marginLeft: isMobile ? '30px' : '0'}}
                         className={styles.foot_right}>
                      {items.children.map((item, index) => {
                        return (
                          <NavLink key={index.toString()} style={{marginTop: "0.12rem"}}
                                   to={item.path ? item.path : items.path} state={item.state}>
                                                        <span onClick={() => {
                                                          btnProductScroll(item.path ? item.path : items.path, item.state);
                                                        }} className={styles.foot_right_text}>{item.text}</span>
                          </NavLink>
                        )
                      })}
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
      <div style={{display: 'flex', width: isMobile ? '100%' : '85%', marginLeft: "auto", marginRight: "auto"}}>
                <span className={styles.foot_bottom_text}>
                    隐私政策
                </span>
        <span className={styles.foot_bottom_text}>
                    用户协议
                </span>
        <span className={styles.foot_bottom_text}>
                    Copyright © 2020 蚁窝科技 版权所有&nbsp;&nbsp;&nbsp;
                </span>
        <span className={styles.foot_bottom_text}>备案号：&nbsp;</span>
        <a style={{color: "#B5B5B5"}} className={styles.foot_bottom_text} target="_blank"
           href={"https://beian.miit.gov.cn/"}>粤ICP备19134362号-2</a>

        <a target="_blank" href={"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010402003118"} className={styles.foot_bottom_text}
           style={{display: "flex", alignItems: "center", justifyContent: "center", marginLeft: toRemAuto(20)}}>
          <img style={{width: toRemAuto(16), height: toRemAuto(16)}}
               src={require("../../assets/icon/filings.png")} />
          <span style={{color: "#B5B5B5"}}>粤公网安备 44010402003118号</span>
        </a>

      </div>
    </div>
  )
};
export default Footer;