import React from "react";
import styles from "./Main.module.scss";

export const dataSourceModeCard = [
  {
    title: "称重计费",
    titleMargin: "0.16rem",
    text: "首创智能餐饮称重模式：单菜单价、称重计费，每个菜品精准至以克为单位。公平计费，真正实现吃多少付多少。",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/card/weight.png",
    imageMargin: "-0.16rem"
  }, {
    title: "无感支付",
    titleMargin: "0",
    text: "告别排队收银时代！自助取餐，智能结算，顾客取完餐15分钟后免密支付结算，无需排队结账。操作简单，体验流畅，大大节省人工收银成本，降低逃单及坏账率。",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/card/pay.png",
    imageMargin: "0"
  }, {
    title: "自主选餐",
    titleMargin: "0",
    text: "五星级酒店自助餐式体验，顾客完全按照自己意愿选择喜好的菜品。小份多样，更丰富的菜品选择，营养更均衡。按需取餐，杜绝浪费，实现光盘行动。",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/card/select_meal.png",
    imageMargin: "0"
  }, {
    title: "大数据应用",
    titleMargin: "0",
    text: "智能大数据驾驶舱，每日销售分析助力运营者精准备料，避免浪费。营业数据分析为消费者提供不同的用餐建议。多样化补贴活动规则使用不用场景需求！",
    imageMargin: "-0.01rem",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/card/big_data.png"
  }
];
export const dataSourceSolutionCard = [
  {
    title: "自助取餐",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_self_service.png"
  }, {
    title: "智能称重",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_ai_weight.png"
  }, {
    title: "无感支付",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_pay.png"
  }, {
    title: "营养分析",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_nutrition.png"
  }, {
    title: "个性化定制",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_self_service.png"
  }
];
export const dataSourceSolutionModeCard = [
  {
    titles: [
      {
        title: '线上预定高效备餐',
      },
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_reserve.png"
  }, {
    titles: [
      {
        title: '智能化管理',
      },
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_manage.png"
  }, {
    titles: [
      {
        title: '支付方式多元化',
      },
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_more_pay_method.png"
  }, {
    titles: [
      {
        title: '线上线下同步运营',
      },
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_synchronous.png"
  }, {
    titles: [
      {
        title: '精细化运营'
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_refind_manage.png"
  }
];
export const dataSourceAdvDataLeft = [
  {
    num: 500,
    unit: "万+",
    text: "使用用户"
  }, {
    num: 100,
    unit: "+",
    text: "覆盖省市级地区",
  }, {
    num: "600",
    unit: "+",
    text: "服务项目"
  }
];
export const dataSourceAdvRight = [
  {
    num: 30,
    unit: "亿+",
    text: "服务人次"
  }, {
    num: 6,
    unit: "年",
    text: "已平稳运行",
  }, {
    num: "600",
    unit: "+",
    text: "专利及软著"
  }
];
export const dataSourceCusExample = [
  {
    titles: [{title: "广东创新科技职业学院"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/mainCase/guangdongInnovateUniversity.png",
    text: "围绕全校师生的饮食安全需求，广东创新科技职业学院引入蚁窝智能餐饮设备及一系列的食堂管理运作体系，全面打造了一家面积达4500平方米的智慧食堂，就餐座位多达2000个，每天最多可容纳6000人次就餐，多次得到了学生家长和在校师生的高度认可。",
  }, {
    titles: [{title: "山东东营安泰社区"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/mainCase/shanDongTaiAnCommunity.png",
    text: "山东东营安泰社区，蚁窝智慧餐厅能为不同年龄的用户提供多样化的菜品选择，结合《中国居民膳食指南（2022）》的要求，每天的膳食搭配种类不少于12种，每周提供不少于25种食材，用户可以通过手机查看菜品营养摄入情况及每餐营养摄入建议，关爱老年人的就餐健康，真正做到社区食堂的食养结合。",
  }, {
    type: "6",
    titles: [{title: "松湖智谷产业园"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/mainCase/songHuZhiGuIndustrialPark.png",
    text: "松湖智谷以产业生态新城为核心理念，围绕园区企业和员工的需求，引入的蚁窝智能餐饮设备打造了一家面积达5000㎡的智慧餐厅，设备数超过400台，可容纳3000人次就餐，开业至今累计消费人次接近70万。",
  },
];
// export const dataSourceLogo = [
//   {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/huawei.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/samsung.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/lg.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/shenzheng_middle_school.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/scut.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/guangdong_innovate.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/north_car.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/huawei.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/samsung.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/lg.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/shenzheng_middle_school.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/scut.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/guangdong_innovate.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/north_car.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/huawei.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/samsung.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/lg.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/shenzheng_middle_school.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/scut.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/guangdong_innovate.png",
//   }, {
//     image: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/north_car.png",
//   },
// ];
export const dataSourceSteps = [
  {
    title: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/coor_01.png",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_circle_null.png",
    text: "需求对接"
  }, {
    title: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/coor_02.png",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_circle_null.png",
    text: "方案推荐"
  }, {
    title: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/coor_03.png",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_circle_null.png",
    text: "合作签订"
  }, {
    title: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/coor_04.png",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_circle_null.png",
    text: "安装设施"
  }, {
    title: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/coor_05.png",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_circle_null.png",
    text: "上线验收"
  }, {
    title: "https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/coor_06.png",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_circle_null.png",
    text: "跟餐培训"
  }
];
export const dataSourceMonMess = [
  {
    key: "2",
    label: "【热烈欢迎】广东省投资发展促进会领导莅临广州蚁窝考察交流",
    text: "2023年7月13日上午11点，广东省投资发展促进会领导一行莅临广州蚁窝智能科技有限公司开展考察交流活动。我司总经理何厚明、副总经理姚欣蓉、财务经理陈柏艳等陪同参与此次交流活动。"
  }, {
    key: "3",
    label: "稳步前进 | 36家智慧餐厅开业，蚁窝智能2023第二季度精彩回顾",
    text: "2023年第二季度，蚁窝智能继续取得了令人鼓舞的成绩，我们在全国各地相继成功打造了36家智能团餐综合项目，覆盖了华东、华南、华中以及西南地区的多个城市。这些智慧餐厅都采用了蚁窝智能最先进的智慧餐饮设备和软件技术，为用餐者提供了便捷、高效、个性化的用餐体验，受到了广泛的好评和欢迎。"
  },
];